import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';

const ContactPage = () => (
	<ThemeProvider theme={{ mode: 'light' }}>
		<Layout>
			<SEO title="Contact" />
			<div>
				<h1>Contact Me</h1>
				<p>
					<a href="mailto:me@example.com">me@example.com</a>
				</p>

				<form name="ContactPage" netlify>
					<p>
						<label>
							Name <input type="text" name="name" />
						</label>
					</p>
					<p>
						<label>
							Email <input type="email" name="email" />
						</label>
					</p>
					<p>
						<button type="submit">Send</button>
					</p>
				</form>
			</div>
		</Layout>
	</ThemeProvider>
);

export default ContactPage;
